<template>
  <section class="cases" id="cases" v-scroll="handleScroll">
    <div class="wrapper">
      <div class="viewport">
        <div class="case case1">
          <div class="bg">
            <video v-if="this.desktop" preload="auto" muted="muted" autoplay playsinline loop class="videoD"
              src="../assets/video/case1.mp4" data-object-fit="cover" poster="../assets/images/cases/case1.jpg"></video>
            <video v-if="this.mobile" preload="auto" muted="muted" autoplay playsinline loop class="videoM"
              src="../assets/video/case1_mob.mp4" data-object-fit="cover"
              poster="../assets/images/cases/case1_mob.jpg"></video>
            <div class="blur"></div>
          </div>
          <div class="gradient"></div>
          <div class="container">
            <h4>01 / 04</h4>
            <h2>Документальный цикл «Поуехавшие»</h2>
            <h3>cнято для платформы <a href="https://wink.ru/series/pouekhavshie-year-2023" target="_blank">Wink</a> при
              поддержке Системы быстрых платежей</h3>

            <div class="desc">
              Восемь историй о смелых и неравнодушных людях, которые переехали из больших городов <br class="soD">в
              деревню и живут свою лучшую жизнь. Меняясь сами, герои меняют место вокруг себя, даже если это маленькое
              село в глубинке. <br>В 4-х эпизодах мы интегрировали сцены, которые раскрывают преимущества Системы быстрых
              платежей и объясняют, как она работает.
            </div>
            <a class="more" v-on:click="open('modal_1')">подробнее</a>
          </div>
        </div>
        <div class="case case2">
          <div class="bg">
            <video v-if="this.desktop" preload="auto" muted="muted" autoplay playsinline loop class="videoD"
              src="../assets/video/case2.mp4" data-object-fit="cover" poster="../assets/images/cases/case2.jpg"></video>
            <video v-if="this.mobile" preload="auto" muted="muted" autoplay playsinline loop class="videoM"
              src="../assets/video/case2_mob.mp4" data-object-fit="cover"
              poster="../assets/images/cases/case2_mob.jpg"></video>
            <div class="blur"></div>
          </div>
          <div class="gradient"></div>
          <div class="container">
            <h4>02 / 04</h4>
            <h2>Скринлайф-ролик <br class="soM">для корпоративного университета «Ростелекома»</h2>

            <div class="desc">Ролик в формате скринлайф: сюжет развивается на экране компьютера героя ― благодаря такому
              формату нам удалось рассказать о преимуществах корпоративного университета компании «Ростелеком» и показать
              героя, к которому <br class="soD">без труда подключится аудитория</div>
          </div>
        </div>
        <div class="case case3">
          <div class="bg">
            <video v-if="this.desktop" preload="auto" muted="muted" autoplay playsinline loop class="videoD"
              src="../assets/video/case3.mp4" data-object-fit="cover" poster="../assets/images/cases/case3.jpg"></video>
            <video v-if="this.mobile" preload="auto" muted="muted" autoplay playsinline loop class="videoM"
              src="../assets/video/case3_mob.mp4" data-object-fit="cover"
              poster="../assets/images/cases/case3_mob.jpg"></video>
            <div class="blur"></div>
          </div>
          <div class="gradient"></div>
          <div class="container">
            <h4>03 / 04</h4>
            <h2>Документальный сериал «Мамы»</h2>
            <h3>cнято для платформы <a href="https://start.ru/watch/mamy" target="_blank">START</a> <br>при поддержке
              Apple</h3>

            <div class="desc">Четыре серии и четыре героини ― <br class="soM">у каждой из них собственная история
              материнства и свой взгляд на воспитание. Весь проект снят на телефоны iPhone 12 Pro, предоставленные
              компанией Apple</div>
            <a class="more" v-on:click="open('modal_2')">подробнее</a>
          </div>
        </div>
        <div class="case case4">
          <div class="bg">
            <video v-if="this.desktop" preload="auto" muted="muted" autoplay playsinline loop class="videoD"
              src="../assets/video/case4.mp4" data-object-fit="cover" poster="../assets/images/cases/case4.jpg"></video>
            <video v-if="this.mobile" preload="auto" muted="muted" autoplay playsinline loop class="videoM"
              src="../assets/video/case4_mob.mp4" data-object-fit="cover"
              poster="../assets/images/cases/case4_mob.jpg"></video>
            <div class="blur"></div>
          </div>
          <div class="gradient"></div>
          <div class="container">
            <h4>04 / 04</h4>
            <h2>Гатчина. Молчание Сильвии</h2>

            <div class="desc">История 867 дней фашистской оккупации Гатчины в годы Великой Отечественной войны</div>
          </div>
        </div>
      </div>
    </div>
    <div class="boxes">
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
      <div class="box"></div>
    </div>
  </section>
</template>
<script>
import store from '@/store.js'

let lastScrollY = 0;
let direction = 0;
let directionCheckInterval = 0;

const checkReady = function () {
  this.parentElement.classList.add("ready")
  this.play();
};

export default {
  name: 'cases',
  data() {
    return {
      desktop: false,
      mobile: false,
      store,
    }
  },
  methods: {
    handleScroll: function (evt, el) {
      var section = document.querySelector('section.cases');
      var viewport = document.querySelector('section.cases .viewport');
      var count = viewport.children.length;

      var w = window.innerWidth;
      var h = window.innerHeight;
      var top = section.offsetTop;

      let left = (window.scrollY > top) ? top - window.scrollY : 0;
      if (left < -(w * (count - 1))) left = -w * (count - 1);

      viewport.style.transform = 'translate3d(' + left + 'px, 0px, 0px)';

      if (lastScrollY > window.scrollY) direction = -1;
      else if (lastScrollY < window.scrollY) direction = 1;
      else direction = 0;

      lastScrollY = window.scrollY;

      clearTimeout(directionCheckInterval);
      directionCheckInterval = setTimeout(() => {
        document.querySelectorAll('section.cases .boxes .box').forEach(function (elem, ind) {
          if (window.scrollY >= section.offsetTop + elem.offsetTop && window.scrollY < section.offsetTop + elem.offsetTop + elem.offsetHeight && ind < count - 1) {
            if (window.scrollY !== section.offsetTop + elem.offsetTop) {
              if (direction === -1) {
                window.scroll({
                  top: section.offsetTop + elem.offsetTop,
                  behavior: 'smooth'
                });
              } else if (direction === 1) {
                window.scroll({
                  top: section.offsetTop + elem.offsetTop + elem.offsetHeight,
                  behavior: 'smooth'
                });
              }
            }
          }
        })
      }, 500);

    },
    open: function (name) {
      document.querySelector('body').style.overflow = 'hidden';
      this.store.openModal = name;
    },
    onResize: function () {
      this.handleScroll();
      this.checkWidth();
    },
    checkWidth: function () {
      if (window.innerWidth < 768) {
        if (this.desktop || !this.mobile) {
          this.removeEventListener();
          this.desktop = false
          this.mobile = true
        }
      } else {
        if (!this.desktop || this.mobile) {
          this.removeEventListener();
          this.desktop = true
          this.mobile = false
        }
      }
    },
    removeEventListener: function () {
      document.querySelector('section.cases .case1 .bg')?.classList.remove("ready");
      document.querySelector('section.cases .case1 .bg')?.classList.remove("ready");

      document.querySelector('section.cases .case2 .bg')?.classList.remove("ready");
      document.querySelector('section.cases .case2 .bg')?.classList.remove("ready");

      document.querySelector('section.cases .case3 .bg')?.classList.remove("ready");
      document.querySelector('section.cases .case3 .bg')?.classList.remove("ready");

      document.querySelector('section.cases .case1 .bg')?.removeEventListener('loadeddata', checkReady);
      document.querySelector('section.cases .case1 .bg')?.removeEventListener('loadeddata', checkReady);

      document.querySelector('section.cases .case2 .bg video.videoD')?.removeEventListener('loadeddata', checkReady);
      document.querySelector('section.cases .case2 .bg video.videoM')?.removeEventListener('loadeddata', checkReady);

      document.querySelector('section.cases .case3 .bg video.videoD')?.removeEventListener('loadeddata', checkReady);
      document.querySelector('section.cases .case3 .bg video.videoM')?.removeEventListener('loadeddata', checkReady);

      document.querySelector('section.cases .case4 .bg')?.classList.remove("ready");
      document.querySelector('section.cases .case4 .bg')?.classList.remove("ready");
    },
    addEventListener: function () {
      document.querySelector('section.cases .case1 .bg video.videoD')?.addEventListener('loadeddata', checkReady, false);
      document.querySelector('section.cases .case1 .bg video.videoM')?.addEventListener('loadeddata', checkReady, false);

      document.querySelector('section.cases .case2 .bg video.videoD')?.addEventListener('loadeddata', checkReady, false);
      document.querySelector('section.cases .case2 .bg video.videoM')?.addEventListener('loadeddata', checkReady, false);

      document.querySelector('section.cases .case3 .bg video.videoD')?.addEventListener('loadeddata', checkReady, false);
      document.querySelector('section.cases .case3 .bg video.videoM')?.addEventListener('loadeddata', checkReady, false);

      document.querySelector('section.cases .case4 .bg video.videoD')?.addEventListener('loadeddata', checkReady, false);
      document.querySelector('section.cases .case4 .bg video.videoM')?.addEventListener('loadeddata', checkReady, false);

      document.querySelector('section.cases .case1 .bg video.videoD')?.load();
      document.querySelector('section.cases .case1 .bg video.videoM')?.load();

      document.querySelector('section.cases .case2 .bg video.videoD')?.load();
      document.querySelector('section.cases .case2 .bg video.videoM')?.load();

      document.querySelector('section.cases .case3 .bg video.videoD')?.load();
      document.querySelector('section.cases .case3 .bg video.videoM')?.load();

      document.querySelector('section.cases .case4 .bg video.videoD')?.load();
      document.querySelector('section.cases .case4 .bg video.videoM')?.load();
    },
  },
  beforeMount: async function () {
    window.addEventListener("resize", this.onResize);
    this.checkWidth();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.onResize);
  },
  mounted: function () {
    this.handleScroll();
    this.addEventListener();
  },
  updated: function () {
    this.addEventListener();
  }
}
</script>

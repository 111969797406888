<template>
    <section class="formats" id="formats">
      <div class="gradient"></div>
      <div class="container">
        <h2>Форматы</h2>
        <div class="list">
          <div class="item" v-on:click="open('modal_f_1')">
            <div class="raw">
              <div class="n">01</div>
              <div class="title">Спецпроект</div>
              <div class="desc">Спонсорский проект на совместно сформулированную вместе с брендом и партнерами проекта тему</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f1.png" class="fd">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f1/1.jpg">
                    <img src="../assets/images/formats/f1/2.jpg">
                    <img src="../assets/images/formats/f1/3.jpg">
                    <img src="../assets/images/formats/f1/4.jpg">
                    <img src="../assets/images/formats/f1/5.jpg">
                    <img src="../assets/images/formats/f1/6.jpg">
                    <img src="../assets/images/formats/f1/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_2')">
            <div class="raw">
              <div class="n">02</div>
              <div class="title">Сериал</div>
              <div class="desc">Совместно с брендом и другими коммерческими партнерами мы разрабатываем сериал, который раскрывает несколько важных для бренда тем</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title withMark">
                        <img src="../assets/images/formats/f2.png">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f2/1.jpg">
                    <img src="../assets/images/formats/f2/2.jpg">
                    <img src="../assets/images/formats/f2/3.jpg">
                    <img src="../assets/images/formats/f2/4.jpg">
                    <img src="../assets/images/formats/f2/5.jpg">
                    <img src="../assets/images/formats/f2/6.jpg">
                    <img src="../assets/images/formats/f2/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_3')">
            <div class="raw">
              <div class="n">03</div>
              <div class="title">Полнометражный <br>фильм</div>
              <div class="desc">Лицензию на показ полнометражного фильма можно предлагать для покупки или аренды всем существующим онлайн-платформам. Кроме того, полнометражный фильм получает дополнительный охват благодаря показам <br class="soD">на фестивалях документального кино</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f3.png" class="fu">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f3/1.jpg">
                    <img src="../assets/images/formats/f3/2.jpg">
                    <img src="../assets/images/formats/f3/3.jpg">
                    <img src="../assets/images/formats/f3/4.jpg">
                    <img src="../assets/images/formats/f3/5.jpg">
                    <img src="../assets/images/formats/f3/6.jpg">
                    <img src="../assets/images/formats/f3/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_4')">
            <div class="raw">
              <div class="n">04</div>
              <div class="title">Короткометражный <br>фильм</div>
              <div class="desc">Документальный формат, в котором за небольшое количество экранного времени мы раскрываем важные <br class="soD">для бренда ценности и темы, его философию и смыслы</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f4.png" class="fu">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f4/1.jpg">
                    <img src="../assets/images/formats/f4/2.jpg">
                    <img src="../assets/images/formats/f4/3.jpg">
                    <img src="../assets/images/formats/f4/4.jpg">
                    <img src="../assets/images/formats/f4/5.jpg">
                    <img src="../assets/images/formats/f4/6.jpg">
                    <img src="../assets/images/formats/f4/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_5')">
            <div class="raw">
              <div class="n">05</div>
              <div class="title">фильм-событие</div>
              <div class="desc">Короткометражный и полнометражный формат о событии ― концерте, конференции, чемпионате, соревновании, презентации, запуске или разработке нового продукта</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f5.png">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f5/1.jpg">
                    <img src="../assets/images/formats/f5/2.jpg">
                    <img src="../assets/images/formats/f5/3.jpg">
                    <img src="../assets/images/formats/f5/4.jpg">
                    <img src="../assets/images/formats/f5/5.jpg">
                    <img src="../assets/images/formats/f5/6.jpg">
                    <img src="../assets/images/formats/f5/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_6')">
            <div class="raw">
              <div class="n">06</div>
              <div class="title">Имиджевый ролик/<br>видеопрезентация</div>
              <div class="desc">Рекламный ролик, основным приемом которого выступает документальный формат передачи информации о бренде <br class="soD">и его ценностях, товарах и услугах</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f6.png" class="fud">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f6/1.jpg">
                    <img src="../assets/images/formats/f6/2.jpg">
                    <img src="../assets/images/formats/f6/3.jpg">
                    <img src="../assets/images/formats/f6/4.jpg">
                    <img src="../assets/images/formats/f6/5.jpg">
                    <img src="../assets/images/formats/f6/6.jpg">
                    <img src="../assets/images/formats/f6/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_7')">
            <div class="raw">
              <div class="n">07</div>
              <div class="title">Скринлайф</div>
              <div class="desc">Этот формат позволяет детально рассказать <br class="soD">об операционной системе, сайте, приложении <br class="soD">и любом диджитал-продукте</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f7.png" class="fu">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f7/1.jpg">
                    <img src="../assets/images/formats/f7/2.jpg">
                    <img src="../assets/images/formats/f7/3.jpg">
                    <img src="../assets/images/formats/f7/4.jpg">
                    <img src="../assets/images/formats/f7/5.jpg">
                    <img src="../assets/images/formats/f7/6.jpg">
                    <img src="../assets/images/formats/f7/7.jpg">
                </div>
            </div>
          </div>
          <div class="item" v-on:click="open('modal_f_8')">
            <div class="raw">
              <div class="n">08</div>
              <div class="title">Образовательный <br>контент</div>
              <div class="desc">Видеокурс, серия обучающих роликов, обучающий короткий метр, эксплейнер — любые форматы, которые позволят потребителю лучше познакомиться с вашим продуктом</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f8.png" class="fu">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f8/1.jpg">
                    <img src="../assets/images/formats/f8/2.jpg">
                    <img src="../assets/images/formats/f8/3.jpg">
                    <img src="../assets/images/formats/f8/4.jpg">
                    <img src="../assets/images/formats/f8/5.jpg">
                    <img src="../assets/images/formats/f8/6.jpg">
                    <img src="../assets/images/formats/f8/7.jpg">
                </div>
            </div>
          </div>
          <div class="item">
            <div class="raw">
              <div class="n">09</div>
              <div class="title">материалы <br>для соцсетей</div>
              <div class="desc">Вертикальный и горизонтальный контент в документальном и полудокументальном формате для продвижения <br class="soD">в социальных сетях</div>
            </div>
            <div class="wrap">
                <div class="viewport">
                    <div class="title">
                        <img src="../assets/images/formats/f9.png" class="fud">
                    </div>
                    <div class="refs">/ референсы /</div>
                    <img src="../assets/images/formats/f9/1.jpg">
                    <img src="../assets/images/formats/f9/2.jpg">
                    <img src="../assets/images/formats/f9/3.jpg">
                    <img src="../assets/images/formats/f9/4.jpg">
                    <img src="../assets/images/formats/f9/5.jpg">
                    <img src="../assets/images/formats/f9/6.jpg">
                    <img src="../assets/images/formats/f9/7.jpg">
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import store from '@/store.js'  

export default {
  name: 'formats',
  data(){return{
    store,
  }},
  methods: {
    open: function (name) {
        document.querySelector('body').style.overflow = 'hidden';
        this.store.openModal=name;
    },
  },
}
</script>

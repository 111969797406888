import '@/assets/css/styles.scss';
import '@/assets/css/main.scss';
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import Vue2TouchEvents from 'vue2-touch-events'
import VueMarqueeSlider from 'vue-marquee-slider';

axios.defaults.withCredentials = true
Vue.use(VueMarqueeSlider)
Vue.use(Vue2TouchEvents)
 
Vue.config.productionTip = false
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

var bootstrap=async function(){
  new Vue({
    render: h => h(App)
  }).$mount('#app')
}
bootstrap();

<template>
  <div id="app">
    <div>
      <home/>
      <about/>
      <cases/>
    </div>
    <div class="pinkbg">
      <formats/>
      <persons/>
    </div>
    <div class="pinkbg2">
      <footerBlock/>
    </div>
    <modals/>
  </div>
</template>
<script>
import home from './blocks/home.vue'
import about from './blocks/about.vue'
import cases from './blocks/cases.vue'
import formats from './blocks/formats.vue'
import persons from './blocks/persons.vue'
import modals from './blocks/modals.vue'
import footerBlock from './blocks/footer.vue'

export default {
  name: 'app',
  components: {
    home,
    about,
    cases,
    formats,
    persons,
    modals,
    footerBlock,
  },
  data(){return{
  }},
  methods: {
  }
}
</script>
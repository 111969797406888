<template>
  <section class="about" id="about">
    <div class="gradient"></div>
    <div class="container">
      <h2>Spotlight документальное кино</h2>
      <div class="crystal">
        <div class="object"></div>
      </div>
      <div class="article">
        Наша цель ― повысить количество и качество независимых документальных фильмов и сериалов, развивать индустрию
        документального кино в России и создавать
        <br class="soD">документальные блокбастеры<br><br>Наша компания в числе первых на российском рынке сняла брендовые
        интеграции <br class="soM">в документальном сериале <br class="soM">для крупного стримингового видеосервиса. Мы
        планируем развивать <br class="soD">формат документальной нативной рекламы<br><br>Несмотря на то, что
        документальное кино – основное наше направление, мы также снимаем художественные фильмы и сериалы, разрабатываем
        креативы и осуществляем девелопмент игровых проектов
        <br><br>
        А еще закрываем весь маркетинг проектов с помощью партнерского агентства <a
          href="https://matreshkadigital.com">Matréshka Digital</a>
      </div>
      <h2>цели и задачи документальной рекламы</h2>
      <div class="goals">
        <div class="item">
          <div class="raw">
            <div class="n">01</div>
            <div class="title">цели</div>
            <div class="desc">Бренд ― это не только уникальный логотип или продукт. Важны ценности, которые наш клиент
              транслирует. Формат документальной рекламы позволяет бренду высказаться на важные для потребителя темы и
              через истории героев показать, <br class="soD">как конкретные действия помогают конкретному человеку</div>
          </div>
        </div>
        <div class="item">
          <div class="raw">
            <div class="n">02</div>
            <div class="title">задачи</div>
            <div class="desc">Документальная реклама развлекает, образовывает, вдохновляет и этим вызывает благодарность
              со стороны потребителя. Он <br class="soM">с удовольствием поддерживает социально-ответственные бренды, а
              контентом делится <br class="soM">с друзьями и близкими</div>
          </div>
        </div>
        <div class="item">
          <div class="raw">
            <div class="n">03</div>
            <div class="title">вывод</div>
            <div class="desc">Такой контент не просто решает задачи коммерческой рекламы, но и формирует эмоциональную
              связь с брендом, повышает уровень доверия к нему. А ещё демонстрирует потребителю, что бренд заботится не
              только <br class="soM">о финансовых показателях, но подает пример социальной ответственности своей
              философией</div>
          </div>
        </div>
      </div>

      <vue-marquee-slider id="marquee" :speed="10000" :autoWidth="true" :space="0">
        <img src="../assets/images/logo.png">
      </vue-marquee-slider>
    </div>
  </section>
</template>
<script>

export default {
  name: 'About',
}
</script>

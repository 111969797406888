<template>
  <footer>
    <div class="gradient"></div>
    <div class="partners">
      <h2>наши партнеры</h2>

      <vue-marquee-slider id="partners-list" class="list" :speed="20000" :autoWidth="true" :space="0">
        <div class="img sbp"><img src="../assets/images/partners/sbp.svg"></div>
        <div class="img mgimo"><img src="../assets/images/partners/mgimo.svg"></div>
        <div class="img wink"><img src="../assets/images/partners/wink.svg"></div>
        <div class="img media-telecom"><img src="../assets/images/partners/media-telecom.png"></div>
        <div class="img start"><img src="../assets/images/partners/start.svg"></div>
        <div class="img cdk"><img src="../assets/images/partners/cdk.svg"></div>
        <div class="img nmg"><img src="../assets/images/partners/nmg.svg"></div>
        <div class="img gpm"><img src="../assets/images/partners/gpm.svg"></div>
        <div class="img matreshka"><img src="../assets/images/partners/matreshka.svg"></div>
      </vue-marquee-slider>
    </div>
    <div class="footer">
      <div class="logo"></div>
      <a class="button" href="https://forms.gle/6yj8ty8xHnS8LNwx7" target="_blank">расскажите о своем проекте</a>
      <div class="contacts">
        <a href="mailto:info@spotlightproduction.ru" class="mail">info@spotlightproduction.ru</a>
        <a href="tel:+79055272122" class="phone">+7 (905) 527-21-22</a>
      </div>
      <div class="menu menu1">
        <a v-on:click="to('about')">о нас</a>
        <a v-on:click="to('cases')">кейсы</a>
        <a v-on:click="to('formats')">форматы</a>
        <a v-on:click="to('founders')" class="soM">основатели</a>
        <a v-on:click="to('team_mob')" class="soM">команда</a>
        <a v-on:click="to('authors_mob')" class="soM">авторы</a>
      </div>
      <div class="menu menu2">
        <a v-on:click="to('founders')">основатели</a>
        <a v-on:click="to('team')">команда</a>
        <a v-on:click="to('authors')">авторы</a>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'footerX',
  methods: {
    to: function (name) {
      var section = document.querySelector('#' + name);
      if (!section) return;
      window.scroll({
        top: section.getBoundingClientRect().top + document.documentElement.scrollTop,
        behavior: 'smooth'
      });
    },
  },
}

</script>
<template>
  <section class="home" id="home">
    <div class="wrapper">
      <div class="bg">
        <video v-if="this.desktop" preload="auto" muted="muted" autoplay playsinline loop class="videoD" src="../assets/video/home.mp4" data-object-fit="cover" poster="../assets/images/homeBg.jpg"></video>
        <video v-if="this.mobile" preload="auto" muted="muted" autoplay playsinline loop class="videoM" src="../assets/video/home_mob.mp4" data-object-fit="cover" poster="../assets/images/homeBgMob.jpg"></video>
        <div class="gradient1"></div>
        <div class="gradient2"></div>
      </div>
      <div class="logo">
        <img src="../assets/images/logo.png">
      </div>
      <div class="desc soD">
        <div class="dLine"><div class="dLineContent">Spotlight — компания по производству кино и рекламы.</div></div>
        <div class="dLine"><div class="dLineContent">Мы рассказываем о мире в документальном формате,</div></div>
        <div class="dLine"><div class="dLineContent">вдохновляющем зрителя</div></div>
      </div>
      <div class="desc soM">
        Spotlight — компания <br class="soM">по производству кино <br class="soM">и рекламы.<br class="soD">
        Мы рассказываем <br class="soM">о мире в документальном формате,<br class="soD">
        вдохновляющем зрителя<br class="soM">&nbsp;
      </div>
    </div>
  </section>
</template>
<script>

const checkReady = function() {
  this.classList.add("ready")
  this.play();
  document.querySelector('section.home .desc').classList.add("ready");
};

export default {
  name: 'home',
  data(){return{
    desktop:false,
    mobile:false,
  }},
  components: {
  },
  methods: {
    checkWidth: function(){
      if(window.innerWidth<768){
        if(this.desktop||!this.mobile){
          this.removeEventListener();
          this.desktop=false
          this.mobile=true
        }
      }else{
        if(!this.desktop||this.mobile){
          this.removeEventListener();
          this.desktop=true
          this.mobile=false
        }
      }
    },
    onResize:function () {
      this.checkWidth();
    },
    removeEventListener:function () {
      document.querySelector('section.home .bg video.videoD')?.classList.remove("ready");
      document.querySelector('section.home .bg video.videoM')?.classList.remove("ready");
      document.querySelector('section.home .bg video.videoD')?.removeEventListener('loadeddata', checkReady);
      document.querySelector('section.home .bg video.videoM')?.removeEventListener('loadeddata', checkReady);
    },
    addEventListener:function () {
      document.querySelector('section.home .bg video.videoD')?.addEventListener('loadeddata', checkReady, false);
      document.querySelector('section.home .bg video.videoM')?.addEventListener('loadeddata', checkReady, false);
      document.querySelector('section.home .bg video.videoD')?.load();
      document.querySelector('section.home .bg video.videoM')?.load();
    },
  },
  beforeMount : async function () {
    window.addEventListener("resize", this.onResize);
    this.checkWidth();
  },
  beforeDestroy:function () {
    window.removeEventListener("resize", this.onResize);
  },
  mounted : function () {
    this.addEventListener();
  },
  updated: function () {
    this.addEventListener();
  }
}

</script>
<template>
  <div class="modals">
            <div class="modal modal_1" v-if="store.openModal=='modal_1'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>документальный цикл «поуехавшие»</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/JQzqr0lwmEE?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title"><span>01/</span> Борис Акимов</div>
                                        <div class="desc">В одном из эпизодов про основателя сообщества #счастливые Бориса Акимова мы сняли интеграционную сцену: в ней герой оплачивает покупку через Систему быстрых платежей. Сцена раскрывает преимущества системы и объясняет, как она работает</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/AKF8gP7y96A?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title"><span>02/</span> Гузель Санжапова</div>
                                        <div class="desc">В другом эпизоде ― про основательницу производства крем-меда из деревни Малый Турыш Гузель Санжапову ― мы интегрировали Систему быстрых платежей, чтобы объяснить удобство системы для предпринимателей</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/4yjK2PPc3Qw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title"><span>03/</span> Екатерина зю</div>
                                        <div class="desc">Серия про основательницу ретрит-курсов в Адыгее Катерину Зю позволила нам показать в кадре, как можно оплачивать покупки в магазине через Систему быстрых платежей</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WeObMUN121o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title"><span>04/</span> Евгений Павздерин</div>
                                        <div class="desc">В этой серии с помощью Системы быстрых платежей можно поддержать программу «Учитель для России» благотворительного фонда «Новый учитель»</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_2" v-if="store.openModal=='modal_2'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>документальный сериал «мамы»</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <img src="../assets/images/moms/mom1.png">
                                    <div class="content">
                                        <div class="title"><span>01/</span> серия</div>
                                        <div class="desc">Героинь этой истории, Вику из Таганрога и Риту из Краснодара, сближает не только то, что их дети живут отдельно. Они — суррогатные мамы</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <img src="../assets/images/moms/mom2.png">
                                    <div class="content">
                                        <div class="title"><span>02/</span> серия</div>
                                        <div class="desc">Дагестанка Лилу проходит путь от УЗИ на поздних сроках до родов. О важности феминистических ценностей и стереотипах в отношении беременности</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <img src="../assets/images/moms/mom3.png">
                                    <div class="content">
                                        <div class="title"><span>03/</span> серия</div>
                                        <div class="desc">Ане чуть больше тридцати, и она потеряла двоих детей. Почему это случилось? Как это пережить и заставить себя радоваться жизни?</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <img src="../assets/images/moms/mom4.png">
                                    <div class="content">
                                        <div class="title"><span>04/</span> серия</div>
                                        <div class="desc">Многодетная мама Кристина живёт с пятью детьми без мужа. У неё была тяжёлая депрессия, но героиня смогла справиться благодаря терапии</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_1" v-if="store.openModal=='modal_f_1'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>спецпроект (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/d3D7IWTohps" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">5B</div>
                                        <div class="desc">Полнометражный документальный фильм о медсестрах, работавших в первом отделении помощи больным СПИДом. Финансирование проекта осуществила компания Johnson & Johnson. Фильм стал участником программы компании по обучению и поддержке медсестер, а также взял сразу несколько наград на фестивале «Каннские львы»</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe src="https://player.vimeo.com/video/409762797?h=d04e661190&color=ffffff&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                    
                                    <div class="content">
                                        <div class="title">Real Beauty by Dove</div>
                                        <div class="desc">Серия рекламных документальных видео «Real Beauty by Dove» (креативное агентство Ogilvy) не просто вдвое увеличила продажи бренда, но и подняла вопрос о стандартах красоты и их влиянии на реализацию женщины в обществе</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_2" v-if="store.openModal=='modal_f_2'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>сериалы (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <a href="https://www.kinopoisk.ru/series/5172825/" target="_blank">
                                        <img src="../assets/images/formats/r2/1.jpg">
                                    </a>
                                    <div class="content">
                                        <div class="title">50/50</div>
                                        <div class="desc">Документальный сериал о предпринимателях при поддержке банка «Точка»</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/R_IN8HGyPKU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">Identity</div>
                                        <div class="desc">Документальный сериал для биотех-компании 23andMe из 23-х историй об исследовании ДНК и его влиянии на человечество</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_3" v-if="store.openModal=='modal_f_3'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>полнометражные фильмы (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <a href="https://www.kinopoisk.ru/film/609155/" target="_blamk">
                                        <img src="../assets/images/formats/r3/1.jpg">
                                    </a>
                                    <div class="content">
                                        <div class="title">Мечты Дзиро о суши</div>
                                        <div class="desc">История о владельце японского ресторана Дзиро Оно, посвятившего всю свою жизнь приготовлению суши и получившему три звезды Мишлен</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <a href="https://www.kinopoisk.ru/film/397184/" target="_blamk">
                                        <img src="../assets/images/formats/r3/2.jpg">
                                    </a>
                                    <div class="content">
                                        <div class="title">История Pixar</div>
                                        <div class="desc">История создания и развития легендарной анимационной студии Pixar Animations и людях, работающих в ней</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_4" v-if="store.openModal=='modal_f_4'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>Короткометражный фильм  (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <a href="https://www.kinopoisk.ru/film/5089521/" target="_blamk">
                                        <img src="../assets/images/formats/r4/1.jpg">
                                    </a>
                                    <div class="content">
                                        <div class="title">Платежная система </div>
                                        <div class="desc">Документальный фильм при поддержке платежной системы «Мир»</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe src="https://player.vimeo.com/video/382570828?h=329e525475&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">The Birdman</div>
                                        <div class="desc">Докуфикшн о нобелевском лауреате и орнитологе Карле Джонсе, спасающем птиц. А заодно — заявление бренда Volvo о планах на экологическую нейтральность и предпринимаемых для этой цели шагах. Этот ролик — седьмой в документальном цикле «Defiant Pioneers by Gray London for Volvo»</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_5" v-if="store.openModal=='modal_f_5'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>документальный фильм (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <a href="https://www.imdb.com/title/tt5519566/" target="_blamk">
                                        <img src="../assets/images/formats/r5/1.jpg">
                                    </a>
                                    
                                    <div class="content">
                                        <div class="title">The First Monday in May</div>
                                        <div class="desc">Документальный фильм о подготовке сентябрьского выпуска журнала Vogue. Так авторы рассказывают <br class="soD">о влиянии Анны Винтур на модную индустрию</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <a href="https://yandex.ru/yac" target="_blamk">
                                        <img src="../assets/images/formats/r5/2.jpg">
                                    </a>
                                    
                                    <div class="content">
                                        <div class="title">YaC </div>
                                        <div class="desc">Документальный цикл о проектах Яндекса и их разработке: городских сервисах, маркетплейсах и технологиях</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_6" v-if="store.openModal=='modal_f_6'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2> Имиджевый рекламный ролик (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/bDrLF20QG2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">Хорошо там, где заботятся вместе</div>
                                        <div class="desc">Разговор Zewa о заботе и ее магическом эффекте</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/eWOWrDtEmy0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">8 марта: спасибо мамам</div>
                                        <div class="desc">Исследование Mail.ru о влиянии мам на наше воспитание и будущее человека</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_7" v-if="store.openModal=='modal_f_7'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>скринлайф (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/NMqg_2GEfZ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">Google </div>
                                        <div class="desc">Креативные решения с готовыми видео-ответами в приложении Google в формате скринлайф</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/4566J9XQ1nM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div class="content">
                                        <div class="title">Что такое скринлайф</div>
                                        <div class="desc">Screenlife — это новый формат сторителлинга. Все, что видит зритель, происходит на экране того или иного девайса — компьютера, планшета или смартфона</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
            <div class="modal modal_f_8" v-if="store.openModal=='modal_f_8'">
                <div class="panel">
                    <div class="gradient"></div>
                    <div class="close" v-on:click="close"></div>
                    <div class="container">
                        <h2>Образовательный контент (референсы)</h2>
                        <VueSlickCarousel  v-bind="settings">
                            <div class="card">
                                <div class="row">
                                    <a href="https://www.masterclass.com/" target="_blamk">
                                        <img src="../assets/images/formats/r8/1.jpg">
                                    </a>
                                    <div class="content">
                                        <div class="title">masterclass.com</div>
                                        <div class="desc">MasterClass — образовательная платформа, которая предлагает курсы <br class="soM">и мастер-классы экспертов различных направлений</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="row">
                                    <a href="https://urokilegend.ru/" target="_blamk">
                                        <img src="../assets/images/formats/r8/2.jpg">
                                    </a>
                                    <div class="content">
                                        <div class="title">урокилегенд.ру</div>
                                        <div class="desc">«Уроки Легенд» — онлайн-платформа, на которой собраны онлайн-курсы по самым актуальным направлениям в формате edutainment: образование (education) плюс развлечение (entertainment)</div>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>

  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import store from '@/store.js'  

export default {
  name: 'modals',
  components: {
    VueSlickCarousel
  },
  data(){return {
        settings: {
          arrows: true,
          dots: false,
          infinite: false,
          slidesToScroll: 2,
          slidesToShow: 2,
        },
        store,
  }},
  methods: {
    close: function () {
        this.store.openModal='';
        document.querySelector('body').style.overflow = '';
    },
    initSlides:function () {
        if(window.innerWidth < 767.98){
            this.settings.slidesToShow = 1;
            this.settings.slidesToScroll = 1;
        }else{
            this.settings.slidesToShow = 2;
            this.settings.slidesToScroll = 2;
        }
    },
    onResize:function () {
        this.initSlides();
    },
  },
  beforeMount : async function () {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy:function () {
    window.removeEventListener("resize", this.onResize);
  },
  mounted : function () {
    this.initSlides();
  },
}
</script>

<template>
    <section class="persons" id="founders" v-scroll="handleScroll">
        <div class="wrapper">
            <div class="gradient"></div>
            <div class="viewport">
                <div class="personpage">
                    <div class="person person1"></div>
                    <h3>основатели</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Анастасия <br>Бельская</h2>
                            <div class="desc">Генеральный директор компании</div>

                            <div class="bio">Продюсер документального цикла «Девяностые» (more.tv), генеральный продюсер
                                доксериалов «Мамы» (Start), «Поуехавшие» (Wink), генеральный продюсер документального
                                фильма «Конец эпохи пацанов» (Wink). Руководитель магистратуры МГИМО, специализация
                                «Маркетинг в индустрии развлечений»</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="personpage">
                    <div class="person person2"></div>
                    <h3>основатели</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Валерия Давыдова-Калашник</h2>
                            <div class="desc">Креативный директор</div>
                            
                            
                            <div class="bio">Продюсер документальных фильмов Crypto Rush и «Карабаш», креативный продюсер доксериалов «Мамы» (Start), «Поуехавшие» (Wink), бывший шеф-продюсер документального кино в самиздате «Батенька, да вы трансформер». Ведущая подкаста «Чего бы посмотреть?», журналистка, сценаристка и киновед</div>
                        </div>
                    </div>
                </div> -->
                <div class="personpage">
                    <div class="person person3"></div>
                    <h3>основатели</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Анастасия<br>Рослякова</h2>
                            <div class="desc">Операционный директор</div>

                            <div class="bio">Продюсер документального сериала «Продавая себя», продюсер документального
                                цикла Сергея Минаева «Девяностые», исполнительный продюсер доксериалов «Мамы», «Поуехавшие»
                                (Wink). Бывший линейный продюсер и редактор развлекательных программ на канале «Пятница!»
                            </div>
                        </div>
                    </div>
                </div>
                <div class="personpage">
                    <div class="person person4"></div>
                    <h3>основатели</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Мария<br>Виткина</h2>
                            <div class="desc">Креативный директор</div>

                            <div class="bio">Сооснователь и креативный директор агентства Matréshka Digital, креативный
                                продюсер художественного кино, сценарист, шеф-редактор и сценарист доксериалов «Мамы»
                                (Start), «Поуехавшие» (Wink), «Конец эпохи пацанов» (Wink) журналист, искусствовед</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="personpage">
                    <div class="person person5"></div>
                    <h3>команда</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Никита<br>Калашник</h2>
                            <div class="desc">Шеф-редактор и сценарист</div>

                            <div class="bio">В прошлом главный редактор проекта Screenlifer Тимура Бекмамбетова</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person6"></div>
                    <h3>команда</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Ольга<br>Страховская</h2>
                            <div class="desc">Редактор и сценаристка</div>

                            <div class="bio">Куратор профиля «Медиа и дизайн» в Школе дизайна НИУ ВШЭ, журналистка,
                                медиаменеджер. В прошлом — главный редактор Wonderzine, шеф-редактор The Blueprint, редактор
                                изданий Look At Me, The Village, «Афиша»  </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person7"></div>
                    <h3>команда</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Оксана<br>Яковлева</h2>
                            <div class="desc">Линейный продюсер проектов</div>

                            <div class="bio">Продюсер девелопмента, линейный продюсер на производстве, продюсер
                                постпродакшна</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person8"></div>
                    <h3>команда</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Полина<br>Туманян</h2>
                            <div class="desc">Юридическое сопровождение проектов</div>

                            <div class="bio">Высококвалифицированный специалист, который помогает в постоянном контроле
                                (мониторинге) сделок и предоставлении консультаций по текущему либо начинающемуся проекту
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person9"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Степан<br>Поливанов</h2>
                            <div class="desc">Режиссер, оператор и монтажер</div>

                            <div class="bio">Режиссер, оператор и монтажер документальных фильмов, музыкальных клипов,
                                рекламы и анимационных роликов. VFX-дизайнер. Режиссер фильма «Raving Riot» о протестах в
                                Тбилиси в 2018 году</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person10"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Таисия<br>Игуменцева</h2>
                            <div class="desc">Режиссер, сценарист и монтажёр</div>

                            <div class="bio">На Каннском кинофестивале 2012 года в конкурсе студенческих работ Cinéfondation
                                получила первое место за фильм «Дорога на»</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person11"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Василиса<br>Кузьмина</h2>
                            <div class="desc">Режиссер, сценарист, актриса</div>

                            <div class="bio">Автор и режиссер фильмов «Алиса» (2020) и «Ника» (2022)</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person12"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Антон<br>Ланге</h2>
                            <div class="desc">Фотограф и фотохудожник</div>

                            <div class="bio">Участник персональных и коллективных выставок, московских биеннале, лауреат
                                премии «Золотое Яблоко», лауреат премии «Обложка Года». Занимается съёмками собственного
                                документального проекта</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person13"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Альмира<br>Сайфуллина</h2>
                            <div class="desc">Режиссер, оператор и монтажер<br>документального кино</div>

                            <div class="bio">Режиссер, оператор и монтажер документального кино. <br>Автор фильма «Мой папа
                                Чингисхан» (2018)</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person14"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Антон<br>Петров</h2>
                            <div class="desc">Оператор</div>

                            <div class="bio">Оператор, второй оператор Олега Лукичева. Проекты: «Eski — старый, Yeni —
                                новый» , «Угрюм-река», Crypto Rush, «Юшка», «Слабым здесь не место»</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person15"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Никита<br>Розов</h2>
                            <div class="desc">Оператор</div>

                            <div class="bio">Оператор на проектах «MONEYпуляторы», «Художники», «Планета Земля. Увидимся
                                завтра», «Филонов», «Улетающий Монахов» </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="personpage">
                    <div class="person person16"></div>
                    <h3>авторы</h3>
                    <div class="info-panels">
                        <div class="info-panel">
                            <h2>Александра<br>Чухненко</h2>
                            <div class="desc">Режиссер</div>

                            <div class="bio">Режиссер документального сериала «Поуехавшие» (2023) для платформы Wink,
                                режиссер рекламных видео для брендов Sber, Utkonos, Clear Blue, Volkswagen и др.</div>

                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="boxes">
            <div class="box boxMob"></div>
            <div class="box boxMob"></div>
            <div class="box boxMob"></div>
            <!-- <div class="box boxMob"></div>
            <div class="box boxMob" id="team_mob"></div>
            <div class="box boxMob"></div>
            <div class="box boxMob"></div>
            <div class="box boxMob"></div>
            <div class="box" id="authors_mob"></div>
            <div class="box"></div>
            <div class="box" id="team"></div>
            <div class="box"></div>
            <div class="box" id="authors"></div> -->
            <div class="box boxHalf"></div>
            <div class="box"></div>
            <!-- <div class="box"></div> -->
        </div>
    </section>
</template>
<script>
let lastScrollY = 0;
let direction = 0;
let directionCheckInterval = 0;
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    components: {
        VueSlickCarousel
    },
    name: 'persons',
    data() {
        return {
        }
    },
    methods: {
        handleScroll: function (evt, el) {
            var section = document.querySelector('section.persons');
            var viewport = document.querySelector('section.persons .viewport');
            var count = viewport.children.length;

            var w = window.innerWidth;
            var h = window.innerHeight;
            var top = section.offsetTop;

            let left = (window.scrollY > top) ? top - window.scrollY : 0;
            if (left < -(viewport.offsetWidth - w)) left = -(viewport.offsetWidth - w);

            viewport.style.transform = 'translate3d(' + left + 'px, 0px, 0px)';

            if (lastScrollY > window.scrollY) direction = -1;
            else if (lastScrollY < window.scrollY) direction = 1;
            else direction = 0;

            lastScrollY = window.scrollY;

            // clearTimeout(directionCheckInterval);
            // directionCheckInterval = setTimeout(() => {
            //     document.querySelectorAll('section.persons .boxes .box').forEach(function (elem, ind) {
            //         if (window.scrollY >= section.offsetTop + elem.offsetTop && window.scrollY < section.offsetTop + elem.offsetTop + elem.offsetHeight && ind < count - 1) {
            //             if (window.scrollY !== section.offsetTop + elem.offsetTop) {
            //                 if (direction === -1) {
            //                     window.scroll({
            //                         top: section.offsetTop + elem.offsetTop,
            //                         behavior: 'smooth'
            //                     });
            //                 } else if (direction === 1) {
            //                     window.scroll({
            //                         top: section.offsetTop + elem.offsetTop + elem.offsetHeight,
            //                         behavior: 'smooth'
            //                     });
            //                 }
            //             }
            //         }
            //     })
            // }, 500);

        },
        onResize: function () {
            this.handleScroll();
        },
    },
    beforeMount: async function () {
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy: function () {
        window.removeEventListener("resize", this.onResize);
    },
    mounted: function () {
        this.handleScroll();
    },
}
</script>
